.closeButton {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    left: 95%;
    width: 50px;
    height: 50px;
    font-size: 1.2em;
    transition: 0.2s;
    cursor: pointer;
    z-index: 5;
    margin-bottom: -50px;
  }
  
  .closeButton:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  }
  