.ErrorContainerInner {
    min-width: 900px;
}

.footerWrapper {
    min-width: 1080px;
    max-width: 1080px;
}

@media (max-width: 1150px) {
    .ErrorMsg {
        padding: 64px 16px !important;
    }
}

@media (max-width: 720px) {
    .ErrorMsg {
        margin: 16px !important;
        padding: 16px !important;
    }
    .ErrorContainer {
        height: 100% !important;
    }
    .ErrorContainerInner {
        width: 100% !important;
    }
}