.ImagesAddon {
    position: absolute;
    right: -1em;
    bottom: -1.6em;
    z-index: 0;
}

@media (max-width: 720px) {
    .ImagesAddon {
        display: none;
    }
}