@media (max-width: 960px) {
    .columns {
        flex-direction: column;
    }
    .columns>div:first-child {
        margin-bottom: 2em;
    }
    .headline h1 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    .content {
        padding-top: 48px;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
}

.headline {
    max-width: 1110px;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
}

.columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

.content {
    width: 100%;
    background: #fff;
    text-align: center;
    flex-grow: 1;
    padding-bottom: 4vh;
    position: relative;
}

.content>h2 {
    display: inline-block;
    margin-right: 0.5em;
    font-size: 4em;
    margin: 0;
}

.content>h2+span {
    display: inline-block;
}