.individual .ops-rep-man-arrow {
    position: absolute;
    top: -170px;
    left: 300px;
}

@media screen and (max-height: 1016px) {
    .wrapper>.headline h1 {
        margin: 40px 0 0px !important;
    }
    .content.individual form.Form {
        margin-bottom: 0;
    }
    .individual.columns ul {
        margin-bottom: 5px;
    }
    .individual.columns .list-item {
        margin-bottom: 30px;
    }
    .individual.columns .list-item:last-child {
        margin-bottom: 0px;
    }
}