.default .ops-rep-man-arrow {
    position: absolute;
    top: -120px;
    left: -190px;
}

@media screen and (min-height: 1060px) {
    .default.columns {
        margin-top: 80px;
    }
}

@media screen and (min-height: 967px) {
    .default.columns {
        padding-bottom: 100px;
    }
}

@media screen and (max-height: 878px) {
    .wrapper>.headline h1 {
        margin: 40px 0 0px !important;
    }
    .default.columns ul {
        margin-bottom: 5px;
    }
    .default.columns .list-item {
        margin-bottom: 30px;
    }
    .default.columns .list-item:last-child {
        margin-bottom: 0px;
    }
}