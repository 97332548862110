.Button {
  background-color: var(--ts-blue);
  border-radius: 20px;
  border: 2px solid var(--ts-blue);
  width: fit-content;
  padding: 0.5rem 3em;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  min-height: 46px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-out;
  margin: 0 auto;
}
.Button:hover {
  background-color: #ffffff;
  color: var(--ts-blue);
  transition: all 0.15s ease-in;
}
