.Form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1em;
    font-size: 0.8em;
}

label {
    display: block;
}

.col {
    flex-basis: 32%;
}

.col .content-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 53px);
}

.top-row {
    height: 46px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

input {
    min-height: 46px;
    border: 2px solid #808080;
    border-radius: 23px;
    padding: 0 1em;
    margin: 0.7em 0;
    outline: none;
}

.floating-label:last-child input {
    margin-bottom: 0;
}

input[type='text'],
input[type='email'],
input[type='phone'],
input[type='date'],
input[type='time'] {
    width: 100%;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

textarea {
    width: 100%;
    min-height: 175px;
    border: 2px solid #808080;
    border-radius: 23px;
    padding: 1em;
    margin: 0.7em 0 0 0;
    resize: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.floating-label {
    position: relative;
}

.floating-label>label {
    position: absolute;
    top: 0.2em;
    left: 20px;
    transition: 0.2s;
    font-size: 0.7em;
    background: #fff;
    padding: 0.1em;
}

.content-inner-row {
    display: flex;
    justify-content: space-between;
}

.content-inner-row.date-time>div.floating-label {
    flex-basis: 45%;
}

input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

input[type='radio']+label {
    /* padding-left: 2.2em; */
    position: relative;
    left: 2em;
    padding-top: 5px;
    padding-left: 5px;
    margin-right: 40px;
    padding-right: 1em;
}

.custom-radio-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-radio-container label {
    padding-right: 2.2em;
    cursor: pointer;
}

.custom-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #808080;
    border-radius: 25%;
    z-index: 0;
}

.custom-radio:after {
    content: '';
    position: absolute;
    display: none;
}

.custom-radio-container input:checked~.custom-radio:after {
    display: block;
    z-index: 0;
}

.custom-radio-container .custom-radio:after {
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
    border-radius: 25%;
    background: var(--ts-blue);
}

.custom-radio-container:hover input~.custom-radio {
    background-color: #eeeeee;
}

.custom-radio-container input:checked~.custom-radio {
    background-color: #fff;
}

.custom-radio-container input[type='radio'] {
    width: 25px;
    height: 25px;
    z-index: 999;
    margin: 0;
}

.SubmitButton {
    background-color: var(--ts-blue);
    border-radius: 20px;
    border: 2px solid var(--ts-blue);
    width: 100%;
    padding: 0.5rem 3em;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    min-height: 46px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out;
    margin: 0 auto;
}

.SubmitButton,
.SubmitButton:focus,
.SubmitButton:active {
    outline: none;
}

.SubmitButton:hover {
    background-color: #ffffff;
    color: var(--ts-blue);
    transition: all 0.15s ease-in;
}