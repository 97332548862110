.list-item {
    font-size: 18px;
    margin-bottom: 36px;
    position: relative;
}

.ops-rep-man-icon {
    margin-right: 9px;
    position: absolute;
    top: -8px;
    left: -28px;
}