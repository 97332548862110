.dev-mode {
    display: flex;
    justify-content: center;
    max-width: 1150px;
    margin: 16px auto;
}

.dev-mode-container {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px #000;
    padding: 20px;
}

.dev-mode>div>div {
    margin: 16px auto;
    align-items: center;
    justify-content: space-between;
}

.dev-mode .Button {
    background-color: #800000;
    border-color: #800000;
}

.dev-mode .Button:last-child {
    margin-right: 0;
}

.dev-mode .Button:hover {
    background-color: #fff;
    color: #800000;
    border-color: #800000;
}

.devButton {
    background-color: #800000;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 90px;
    width: 50px;
    height: 50px;
    font-size: 1.2em;
    transition: 0.2s;
    cursor: pointer;
    z-index: 5;
    margin-bottom: -50px;
    outline: none;
}

.devButton:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}