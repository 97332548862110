#ops-booking-form {
    --ts-blue: #005aa0;
}

@import url("https://p.typekit.net/p.css?s=1&k=toj7gbi&ht=tk&f=6849.6851.6852&a=1049882&app=typekit&e=css");
@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 600;
}

.tk-myriad-pro {
    font-family: "myriad-pro", "sans-serif";
}

.App {
    background-color: #fff;
    background: url(https://business.trustedshops.de/hubfs/1-TS_B2B/OPS/DEV/SB-RM/static/media/background.4e942b1f.png), linear-gradient(#000000, #ffffff);
    background-size: cover;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: MyriadPro-Light, Myriad Pro, "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #000000;
    margin-bottom: -4vw;
    min-height: 95vh;
    position: relative;
    opacity: 0;
    --ts-blue: #005aa0;
}

.App.loaded {
    opacity: 1;
}

.App *:not(i) {
    box-sizing: border-box;
    line-height: 1.4;
    font-family: MyriadPro-Light, Myriad Pro, "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.App code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App b {
    stroke: rgba(0, 0, 0, 0);
    font-weight: 400;
}

.App strong {
    stroke: rgba(0, 0, 0, 0);
    font-weight: 600;
}

.App h1,
.App h2,
.App h3,
.App h4,
.App h5,
.App h6 {
    font-weight: 600;
}

.App h1 {
    font-size: 44px;
}

.App h2 {
    font-size: 35px;
}

.App p {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 1.4;
}

.App a.link {
    color: black;
    font-weight: 600;
    text-decoration: underline;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 1.2vmin);
    height: 100%;
}

.App-link {
    color: #61dafb;
}


/* Hacks for reset.scss */

ul {
    margin: 1em 0;
}