.wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .headline {
    max-width: 1150px;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
  }
  @media (max-width: 960px) {
    .headline h1 {
      width: 100%;
    }
  }
  .single-column {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 5em;
  }