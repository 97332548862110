@import url(https://p.typekit.net/p.css?s=1&k=toj7gbi&ht=tk&f=6849.6851.6852&a=1049882&app=typekit&e=css);
#ops-booking-form body {
  margin: 0;
}

#ops-booking-form .ImagesAddon {
    position: absolute;
    right: -1em;
    bottom: -1.6em;
    z-index: 0;
}

@media (max-width: 720px) {
    #ops-booking-form .ImagesAddon {
        display: none;
    }
}
@media (min-width: 1440px) {
    #ops-booking-form .waveSection {
        margin-bottom: -3vw;
    }
}

@media (max-width: 720px) {
    #ops-booking-form .waveSection {
        display: none;
    }
}
#ops-booking-form .closeButton {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 95%;
    width: 50px;
    height: 50px;
    font-size: 1.2em;
    transition: 0.2s;
    cursor: pointer;
    z-index: 5;
    margin-bottom: -50px;
  }
  
  #ops-booking-form .closeButton:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
  }
  
#ops-booking-form .dev-mode {
    display: flex;
    justify-content: center;
    max-width: 1150px;
    margin: 16px auto;
}

#ops-booking-form .dev-mode-container {
    background: #fff;
    border-radius: 15px;
    box-shadow: 0px 0px 5px 0px #000;
    padding: 20px;
}

#ops-booking-form .dev-mode>div>div {
    margin: 16px auto;
    align-items: center;
    justify-content: space-between;
}

#ops-booking-form .dev-mode .Button {
    background-color: #800000;
    border-color: #800000;
}

#ops-booking-form .dev-mode .Button:last-child {
    margin-right: 0;
}

#ops-booking-form .dev-mode .Button:hover {
    background-color: #fff;
    color: #800000;
    border-color: #800000;
}

#ops-booking-form .devButton {
    background-color: #800000;
    color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 25px;
    right: 90px;
    width: 50px;
    height: 50px;
    font-size: 1.2em;
    transition: 0.2s;
    cursor: pointer;
    z-index: 5;
    margin-bottom: -50px;
    outline: none;
}

#ops-booking-form .devButton:hover {
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.2);
}
#ops-booking-form .Button {
  background-color: var(--ts-blue);
  border-radius: 20px;
  border: 2px solid var(--ts-blue);
  width: -webkit-fit-content;
  width: fit-content;
  padding: 0.5rem 3em;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  min-height: 46px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-out;
  margin: 0 auto;
}
#ops-booking-form .Button:hover {
  background-color: #ffffff;
  color: var(--ts-blue);
  transition: all 0.15s ease-in;
}

#ops-booking-form .list-item {
    font-size: 18px;
    margin-bottom: 36px;
    position: relative;
}

#ops-booking-form .ops-rep-man-icon {
    margin-right: 9px;
    position: absolute;
    top: -8px;
    left: -28px;
}
#ops-booking-form .Paragraph {
    max-width: "530px";
    margin: "0 auto 2rem auto";
    text-align: "center";
    font-size: "1.4rem";
}
#ops-booking-form .footerWrapper {
    width: calc(100% - 80px);
    margin: 10px auto;
    max-width: 1110px;
    display: flex;
    justify-content: space-between;
    color: #666;
    font-size: 11px;
}
#ops-booking-form .default .ops-rep-man-arrow {
    position: absolute;
    top: -120px;
    left: -190px;
}

@media screen and (min-height: 1060px) {
    #ops-booking-form .default.columns {
        margin-top: 80px;
    }
}

@media screen and (min-height: 967px) {
    #ops-booking-form .default.columns {
        padding-bottom: 100px;
    }
}

@media screen and (max-height: 878px) {
    #ops-booking-form .wrapper>.headline h1 {
        margin: 40px 0 0px !important;
    }
    #ops-booking-form .default.columns ul {
        margin-bottom: 5px;
    }
    #ops-booking-form .default.columns .list-item {
        margin-bottom: 30px;
    }
    #ops-booking-form .default.columns .list-item:last-child {
        margin-bottom: 0px;
    }
}
@media (max-width: 960px) {
    #ops-booking-form .columns {
        flex-direction: column;
    }
    #ops-booking-form .columns>div:first-child {
        margin-bottom: 2em;
    }
    #ops-booking-form .headline h1 {
        width: 100%;
    }
}

@media (max-width: 720px) {
    #ops-booking-form .content {
        padding-top: 48px;
    }
}

#ops-booking-form .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
}

#ops-booking-form .headline {
    max-width: 1110px;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
}

#ops-booking-form .columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
}

#ops-booking-form .content {
    width: 100%;
    background: #fff;
    text-align: center;
    flex-grow: 1;
    padding-bottom: 4vh;
    position: relative;
}

#ops-booking-form .content>h2 {
    display: inline-block;
    margin-right: 0.5em;
    font-size: 4em;
    margin: 0;
}

#ops-booking-form .content>h2+span {
    display: inline-block;
}
#ops-booking-form .Form {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1em;
    font-size: 0.8em;
}

#ops-booking-form label {
    display: block;
}

#ops-booking-form .col {
    flex-basis: 32%;
}

#ops-booking-form .col .content-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 53px);
}

#ops-booking-form .top-row {
    height: 46px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

#ops-booking-form input {
    min-height: 46px;
    border: 2px solid #808080;
    border-radius: 23px;
    padding: 0 1em;
    margin: 0.7em 0;
    outline: none;
}

#ops-booking-form .floating-label:last-child input {
    margin-bottom: 0;
}

#ops-booking-form input[type='text'],
#ops-booking-form input[type='email'],
#ops-booking-form input[type='phone'],
#ops-booking-form input[type='date'],
#ops-booking-form input[type='time'] {
    width: 100%;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#ops-booking-form textarea {
    width: 100%;
    min-height: 175px;
    border: 2px solid #808080;
    border-radius: 23px;
    padding: 1em;
    margin: 0.7em 0 0 0;
    resize: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

#ops-booking-form .floating-label {
    position: relative;
}

#ops-booking-form .floating-label>label {
    position: absolute;
    top: 0.2em;
    left: 20px;
    transition: 0.2s;
    font-size: 0.7em;
    background: #fff;
    padding: 0.1em;
}

#ops-booking-form .content-inner-row {
    display: flex;
    justify-content: space-between;
}

#ops-booking-form .content-inner-row.date-time>div.floating-label {
    flex-basis: 45%;
}

#ops-booking-form input[type='radio'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

#ops-booking-form input[type='radio']+label {
    /* padding-left: 2.2em; */
    position: relative;
    left: 2em;
    padding-top: 5px;
    padding-left: 5px;
    margin-right: 40px;
    padding-right: 1em;
}

#ops-booking-form .custom-radio-container {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#ops-booking-form .custom-radio-container label {
    padding-right: 2.2em;
    cursor: pointer;
}

#ops-booking-form .custom-radio {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #808080;
    border-radius: 25%;
    z-index: 0;
}

#ops-booking-form .custom-radio:after {
    content: '';
    position: absolute;
    display: none;
}

#ops-booking-form .custom-radio-container input:checked~.custom-radio:after {
    display: block;
    z-index: 0;
}

#ops-booking-form .custom-radio-container .custom-radio:after {
    top: 5px;
    left: 5px;
    width: 13px;
    height: 13px;
    border-radius: 25%;
    background: var(--ts-blue);
}

#ops-booking-form .custom-radio-container:hover input~.custom-radio {
    background-color: #eeeeee;
}

#ops-booking-form .custom-radio-container input:checked~.custom-radio {
    background-color: #fff;
}

#ops-booking-form .custom-radio-container input[type='radio'] {
    width: 25px;
    height: 25px;
    z-index: 999;
    margin: 0;
}

#ops-booking-form .SubmitButton {
    background-color: var(--ts-blue);
    border-radius: 20px;
    border: 2px solid var(--ts-blue);
    width: 100%;
    padding: 0.5rem 3em;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    min-height: 46px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out;
    margin: 0 auto;
}

#ops-booking-form .SubmitButton,
#ops-booking-form .SubmitButton:focus,
#ops-booking-form .SubmitButton:active {
    outline: none;
}

#ops-booking-form .SubmitButton:hover {
    background-color: #ffffff;
    color: var(--ts-blue);
    transition: all 0.15s ease-in;
}
#ops-booking-form .individual .ops-rep-man-arrow {
    position: absolute;
    top: -170px;
    left: 300px;
}

@media screen and (max-height: 1016px) {
    #ops-booking-form .wrapper>.headline h1 {
        margin: 40px 0 0px !important;
    }
    #ops-booking-form .content.individual form.Form {
        margin-bottom: 0;
    }
    #ops-booking-form .individual.columns ul {
        margin-bottom: 5px;
    }
    #ops-booking-form .individual.columns .list-item {
        margin-bottom: 30px;
    }
    #ops-booking-form .individual.columns .list-item:last-child {
        margin-bottom: 0px;
    }
}
#ops-booking-form .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  #ops-booking-form .headline {
    max-width: 1150px;
    margin: 0 auto;
    width: 100%;
    font-weight: 300;
  }
  @media (max-width: 960px) {
    #ops-booking-form .headline h1 {
      width: 100%;
    }
  }
  #ops-booking-form .single-column {
    max-width: 1150px;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    padding-bottom: 5em;
  }
#ops-booking-form .ErrorContainerInner {
    min-width: 900px;
}

#ops-booking-form .footerWrapper {
    min-width: 1080px;
    max-width: 1080px;
}

@media (max-width: 1150px) {
    #ops-booking-form .ErrorMsg {
        padding: 64px 16px !important;
    }
}

@media (max-width: 720px) {
    #ops-booking-form .ErrorMsg {
        margin: 16px !important;
        padding: 16px !important;
    }
    #ops-booking-form .ErrorContainer {
        height: 100% !important;
    }
    #ops-booking-form .ErrorContainerInner {
        width: 100% !important;
    }
}
#ops-booking-form #ops-booking-form {
    --ts-blue: #005aa0;
}
@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("woff2"), url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("woff"), url("https://use.typekit.net/af/c630c3/000000000000000000017098/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n3&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/cafa63/00000000000000000001709a/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "myriad-pro";
    src: url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/l?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/d?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/80c5d0/00000000000000000001709c/27/a?primer=fff1a989570eb474b8c22c57cc7199e63bfc7e911b750165d0199218f0b7e7cc&fvd=n6&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 600;
}

#ops-booking-form .tk-myriad-pro {
    font-family: "myriad-pro", "sans-serif";
}

#ops-booking-form .App {
    background-color: #fff;
    background: url(https://business.trustedshops.de/hubfs/1-TS_B2B/OPS/DEV/SB-RM/static/media/background.4e942b1f.png), linear-gradient(#000000, #ffffff);
    background-size: cover;
    padding: 0;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: MyriadPro-Light, Myriad Pro, "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #000000;
    margin-bottom: -4vw;
    min-height: 95vh;
    position: relative;
    opacity: 0;
    --ts-blue: #005aa0;
}

#ops-booking-form .App.loaded {
    opacity: 1;
}

#ops-booking-form .App *:not(i) {
    box-sizing: border-box;
    line-height: 1.4;
    font-family: MyriadPro-Light, Myriad Pro, "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

#ops-booking-form .App code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#ops-booking-form .App b {
    stroke: rgba(0, 0, 0, 0);
    font-weight: 400;
}

#ops-booking-form .App strong {
    stroke: rgba(0, 0, 0, 0);
    font-weight: 600;
}

#ops-booking-form .App h1,
#ops-booking-form .App h2,
#ops-booking-form .App h3,
#ops-booking-form .App h4,
#ops-booking-form .App h5,
#ops-booking-form .App h6 {
    font-weight: 600;
}

#ops-booking-form .App h1 {
    font-size: 44px;
}

#ops-booking-form .App h2 {
    font-size: 35px;
}

#ops-booking-form .App p {
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    line-height: 1.4;
}

#ops-booking-form .App a.link {
    color: black;
    font-weight: 600;
    text-decoration: underline;
}

#ops-booking-form .App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-size: calc(10px + 1.2vmin);
    height: 100%;
}

#ops-booking-form .App-link {
    color: #61dafb;
}


/* Hacks for reset.scss */

#ops-booking-form ul {
    margin: 1em 0;
}
